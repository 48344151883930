<template>
    <div class="container">
        <img src="@/assets/logo.png" alt="logo" class="logo">
        <div class="dot" id="dot-1"></div>
        <div class="dot" id="dot-2"></div>
        <div class="center">
            <i class="fa-brands fa-discord"></i>
            <div class="texts">
                <h2>Nie jesteś na naszym discordzie?</h2>
                <h5>Dołącz do naszego discorda już teraz i bądź poinformowany o wszystkim na bieżąco!</h5>
            </div>
            <a href="https://discord.gg/pickrp"><div class="button"><i class="fa-brands fa-discord"></i> <span>DOŁĄCZ</span></div></a>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'MainPage'
    }
</script>

<style scoped>
    .container{
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: url('@/assets/background.png');
        background-size: cover;
        overflow: hidden;
    }

    .dot{
        position: absolute;
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        background-color: white;
        filter: blur(100px);
        opacity: .4;
    }

    #dot-1{
        top: 90%;
        left: -5%;
    }

    #dot-2{
        top: 10%;
        right: -2%;
        background-color: #6c42ed;
    }

    .logo{
        position: absolute;
        top: -4%;
        left: 50%;
        transform: translate(-50%);
        width: 12vw;
        height: auto;
        opacity: .75;
        animation: logoAnim 8s linear infinite;
        /* filter: blur(.7px); */
    }

    @keyframes logoAnim{
        0% {
            transform: translate(-50%) scale(1.0);
        }
        50%{
            transform: translate(-50%) scale(1.1);
        }
        100%{
            transform: translate(-50%) scale(1.0);
        }
    }

    .center{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20vw;
        height: 20vw;
        color: white;
        font-family: 'Poppins', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: .5vw;
    }

    .center > i{
        font-size: 2vw;
        animation: ring 6.5s 0.7s ease-in-out infinite;
    }

    @keyframes ring {
        0% {
            transform: rotate(0);
        }
        1% {
            transform: rotate(30deg);
        }
        3% {
            transform: rotate(-28deg);
        }
        5% {
            transform: rotate(34deg);
        }
        7% {
            transform: rotate(-32deg);
        }
        9% {
            transform: rotate(30deg);
        }
        11% {
            transform: rotate(-28deg);
        }
        13% {
            transform: rotate(26deg);
        }
        15% {
            transform: rotate(-24deg);
        }
        17% {
            transform: rotate(22deg);
        }
        19% {
            transform: rotate(-20deg);
        }
        21% {
            transform: rotate(18deg);
        }
        23% {
            transform: rotate(-16deg);
        }
        25% {
            transform: rotate(14deg);
        }
        27% {
            transform: rotate(-12deg);
        }
        29% {
            transform: rotate(10deg);
        }
        31% {
            transform: rotate(-8deg);
        }
        33% {
            transform: rotate(6deg);
        }
        35% {
            transform: rotate(-4deg);
        }
        37% {
            transform: rotate(2deg);
        }
        39% {
            transform: rotate(-1deg);
        }
        41% {
            transform: rotate(1deg);
        }

        43% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
        }

    .center > .texts{
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: .2vw;
    }

    .center > .texts > h2{
        font-size: .8vw;
        white-space: nowrap;
    }

    .center > .texts > h5{
        font-size: .6vw;
        color: rgba(255, 255, 255, .234);
        font-weight: 400;
        text-align: center;
    }

    .center > a{
        text-decoration: none;
        color: white;
    }

    .center > a > .button{
        background-color: #5662F6;
        padding: .3vw .6vw;
        border-radius: 5px;
        filter: drop-shadow(0 0 .5em rgba(86, 98, 246, .5));
        transition: .2s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .3vw;
        font-size: 1vw;
        margin-top: 1.5vw;
    }

    .center > a > .button > span{
        font-size: .85vw;
    }

    .center > a > .button:hover{
        filter: drop-shadow(0 0 1em rgba(86, 98, 246, .5));
        cursor: pointer;
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        .container{
            background-position-x: 35%;
        }

        .logo{
            width: 40vw;
            top: 0%;
        }

        @keyframes logoAnim{
            0% {
                transform: translate(-50%) scale(1.0);
            }
            50%{
                transform: translate(-50%) scale(1.3);
            }
            100%{
                transform: translate(-50%) scale(1.0);
            }
        }

        .center > i{
            font-size: 10vw;
        }

        .center > .texts > h2{
            font-size: 4vw;
            white-space: nowrap;
        }

        .center > .texts > h5{
            font-size: 2vw;
        }

        .center > a > .button{
            background-color: #5662F6;
            padding: 1vw;
            border-radius: 5px;
            filter: drop-shadow(0 0 1em rgba(86, 98, 246, .5));
            transition: .2s;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1vw;
            font-size: 3vw;
            margin-top: 1.5vw;
        }

        .center > a > .button > span{
            font-size: 3vw;
        }

        .dot{
            display: none;
        }
    }
</style>